<template>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on } ">
        <v-btn
          fab
          @click.stop="btnClick(on)"
        >
          <v-avatar
            size="60"
          >
            <img :src="bid.src" :alt="bid.sb">
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense color="color1 color1Text--text">
          {{player.fullname}}'s Badges
        </v-toolbar>
        <v-card-text class="px-2 pt-2 pb-0 font-weight-bold">
          {{player.fullname}} has earned bids to the following events:
        </v-card-text>
        <v-card-text v-for="(t, i) in tournaments" :key="t.id" class="pa-2 d-flex align-left">
          <v-avatar
            size="100"
          >
            <img :src="t.src || bid.src" :alt="bid.sb">
          </v-avatar>
          <div style="width: calc(100% - 100px)" class="text-left mt-3">
            <div>{{t.name}}</div>
            <div>
              <v-btn
                color="color3"
                class="pa-0"
                text @click.stop="goto(i)" x-small>Jump to event</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: ['bid', 'player'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    tournaments () {
      return this.bid.tournaments.filter(f => f.isPublic)
    }
  },
  methods: {
    goto (i) {
      console.log(i)
      const t = this.bid.tournaments[i]
      this.$router.push({ name: 'tournament-brochure', params: { tournamentId: t.id } })
    },
    btnClick () {
      this.dialog = this.tournaments.length > 0
    }
  }
}
</script>

<style scoped>
  .badgeTitle {
    background:#dae2ed;
    color: #446da3;
    border-bottom: 1px solid #446da3;
  }
</style>
